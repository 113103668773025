import React from 'react';

class SearchCard extends React.Component {
    
    render() {
        
        return (
            <div className="search-input w-100 mb-4">
                <input
                    className   = "input-control control-gray control-search"
                    type        = "text"
                    placeholder = "Search project.." 
                    value       = { this.props.searchText }
                    onChange    = {(e) => this.props.searchItem(e.target.value)}
                />
            </div>
        );
    }
}    

export default SearchCard;