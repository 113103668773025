import Default from '../pics/nets/default.svg';
import Abey from '../pics/nets/abey.svg';
import Algorand from '../pics/nets/algorand.svg';
import Ancient8 from '../pics/nets/ancient8.svg';
import Arbitrum from '../pics/nets/arbitrum.svg';
import ArbitrumNova from '../pics/nets/arbitrum-nova.svg';
import Astar from '../pics/nets/astar.svg';
import Aurora from '../pics/nets/aurora.svg';
import Avalanche from '../pics/nets/avalanche.svg';
import Base from '../pics/nets/base.svg';
import Berachain from '../pics/nets/berachain.svg';
import Bitcoin from '../pics/nets/bitcoin.svg';
import Bitgert from '../pics/nets/bitgert.svg';
import Bitlayer from '../pics/nets/bitlayer.svg';
import Blast from '../pics/nets/blast.svg';
import BNB from '../pics/nets/bnb.svg';
import Boba from '../pics/nets/boba.svg';
import Caduceus from '../pics/nets/caduceus.svg';
import Canto from '../pics/nets/canto.svg';
import Cardano from '../pics/nets/cardano.svg';
import Celo from '../pics/nets/celo.svg';
import Coinflux from '../pics/nets/coinflux.svg';
import Cronos from '../pics/nets/cronos.svg';
import Cyber from '../pics/nets/cyber.svg';
import Degen from '../pics/nets/degen.svg';
import EOS from '../pics/nets/eos.svg';
import Ethereum from '../pics/nets/ethereum.svg';
import EthereumClassic from '../pics/nets/ethereumclassic.svg';
import EvmOs from '../pics/nets/evmos.svg';
import Fantom from '../pics/nets/fantom.svg';
import Flare from '../pics/nets/flare.svg';
import Flow from '../pics/nets/flow.svg';
import Fraxtal from '../pics/nets/fraxtal.svg';
import Fuse from '../pics/nets/fuse.svg';
import Gatechain from '../pics/nets/gatechain.svg';
import Gnosis from '../pics/nets/gnosis.svg';
import Godwoken from '../pics/nets/godwoken.svg';
import Harmony from '../pics/nets/harmony.svg';
import Heco from '../pics/nets/heco.svg';
import Hedera from '../pics/nets/hedera.svg';
import Hychain from '../pics/nets/hychain.svg';
import Icp from '../pics/nets/icp.svg';
import Immutable from '../pics/nets/immutable.svg';
import Inevm from '../pics/nets/inevm.svg';
import Iotex from '../pics/nets/iotex.svg';
import KAVA from '../pics/nets/kava.svg';
import Klaytn from '../pics/nets/klaytn.svg';
import Kroma from '../pics/nets/kroma.svg';
import Kusama from '../pics/nets/kusama.svg';
import Layerzero from '../pics/nets/layerzero.svg';
import Liberty from '../pics/nets/liberty.svg';
import Linea from '../pics/nets/linea.svg';
import Manta from '../pics/nets/manta.svg';
import Mantle from '../pics/nets/mantle.svg';
import Metis from '../pics/nets/metis.svg';
import Mode from '../pics/nets/mode.svg';
import Moonbeam from '../pics/nets/moonbeam.svg';
import Multiversx from '../pics/nets/multiversx.svg';
import Near from '../pics/nets/near.svg';
import OKT from '../pics/nets/okt.svg';
import Optimism from '../pics/nets/optimism.svg';
import Palm from '../pics/nets/palm.svg';
import Platon from '../pics/nets/platon.svg';
import Polkadot from '../pics/nets/polkadot.svg';
import Polygon from '../pics/nets/polygon.svg';
import Pulsechain from '../pics/nets/pulsechain.svg';
import Redlight from '../pics/nets/redlight.svg';
import Redstone from '../pics/nets/redstone.svg';
import Ronin from '../pics/nets/ronin.svg';
import Scroll from '../pics/nets/scroll.svg';
import Secret from '../pics/nets/secret.svg';
import Shimmer from '../pics/nets/shimmer.svg';
import Solana from '../pics/nets/solana.svg';
import Sui from '../pics/nets/sui.svg';
import Syscoin from '../pics/nets/syscoin.svg';
import Telos from '../pics/nets/telos.svg';
import Tezos from '../pics/nets/tezos.svg';
import Thundercore from '../pics/nets/thundercore.svg';
import Ton from '../pics/nets/ton.svg';
import Tron from '../pics/nets/tron.svg';
import Ultron from '../pics/nets/ultron.svg';
import Vechain from '../pics/nets/vechain.svg';
import Velas from '../pics/nets/velas.svg';
import Viction from '../pics/nets/viction.svg';
import WAX from '../pics/nets/wax.svg';
import XAI from '../pics/nets/xai.svg';
import Zetachain from '../pics/nets/zetachain.svg';
import Zilliqa from '../pics/nets/zilliqa.svg';
import Zkfair from '../pics/nets/zkfair.svg';
import ZklinkNova from '../pics/nets/zklink-nova.svg';
import Zksync from '../pics/nets/zksync.svg';
import Zora from '../pics/nets/zora.svg';
import Coingecko from '../pics/socials/coingecko.svg';
import Coinmarketcap from '../pics/socials/coinmarketcap.svg';
import Discord from '../pics/socials/discord.svg';
import Facebook from '../pics/socials/facebook.svg';
import Github from '../pics/socials/github.svg';
import Instagram from '../pics/socials/instagram.svg';
import Linkedin from '../pics/socials/linkedin.svg';
import Medium from '../pics/socials/medium.svg';
import Reddit from '../pics/socials/reddit.svg';
import Telegram from '../pics/socials/telegram.svg';
import Tiktok from '../pics/socials/tiktok.svg';
import Twitter from '../pics/socials/twitter.svg';
import Web from '../pics/socials/web.svg';
import Youtube from '../pics/socials/youtube.svg';


const icons = {
  'default': Default,
  'ABEY': Abey,
  'Algorand': Algorand,
  'Ancient8': Ancient8,
  'Arbitrum One': Arbitrum,
  'Arbitrum Nova': ArbitrumNova,
  'Astar': Astar,
  'Astar zkEVM': Astar,
  'Aurora': Aurora,
  'Avalanche': Avalanche,
  'Base': Base,
  'Berachain': Berachain,
  'Bitcoin': Bitcoin,
  'Bitgert': Bitgert,
  'Bitlayer': Bitlayer,
  'Blast': Blast,
  'BSC': BNB,
  'Boba': Boba,
  'Caduceus': Caduceus,
  'Canto': Canto,
  'Cardano': Cardano,
  'Celo': Celo,
  'Coinflux': Coinflux,
  'Cronos': Cronos,
  'Cyber': Cyber,
  'Degen': Degen,
  'EOS EVM': EOS,
  'Ethereum': Ethereum,
  'Ethereum Classic': EthereumClassic,
  'EvmOs': EvmOs,
  'Fantom': Fantom,
  'Flare': Flare,
  'Flow': Flow,
  'Fraxtal': Fraxtal,
  'Fuse': Fuse,
  'GateChain': Gatechain,
  'Gnosis': Gnosis,
  'Godwoken': Godwoken,
  'Harmony': Harmony,
  'Heco': Heco,
  'Hedera': Hedera,
  'Hychain': Hychain,
  'ICP': Icp,
  'Immutable X': Immutable,
  'Immutable zkEVM': Immutable,
  'inEVM': Inevm,
  'IoTeX': Iotex,
  'KAVA': KAVA,
  'Klaytn': Klaytn,
  'Kroma': Kroma,
  'Kusama': Kusama,
  'Layer Zero': Layerzero,
  'Liberty 2.X': Liberty,
  'Linea': Linea,
  'Manta': Manta,
  'Mantle': Mantle,
  'Metis': Metis,
  'Mode': Mode,
  'Moonbeam': Moonbeam,
  'MultiversX': Multiversx,
  'NEAR': Near,
  'OKT Chain': OKT,
  'Optimism': Optimism,
  'Palm': Palm,
  'PlatON': Platon,
  'Polkadot': Polkadot,
  'Polygon': Polygon,
  'Polygon zkEVM': Polygon,
  'Pulsechain': Pulsechain,
  'Redlight': Redlight,
  'Redstone': Redstone,
  'Ronin': Ronin,
  'Scroll': Scroll,
  'Secret': Secret,
  'Shimmer': Shimmer,
  'Solana': Solana,
  'SUI': Sui,
  'Syscoin': Syscoin,
  'Telos': Telos,
  'Tezos': Tezos,
  'ThunderCore': Thundercore,
  'TON': Ton,
  'Tron': Tron,
  'Ultron': Ultron,
  'VeChain': Vechain,
  'Velas': Velas,
  'Viction': Viction,
  'WAX': WAX,
  'XAI': XAI,
  'X layer': OKT,
  'ZetaChain': Zetachain,
  'Zilliqa': Zilliqa,
  'ZKFair': Zkfair,
  'zkLink Nova': ZklinkNova,
  'zkSync': Zksync,
  'Zora': Zora,
  'Coingecko': Coingecko,
  'Coinmarketcap': Coinmarketcap,
  'Discord': Discord,
  'Facebook': Facebook,
  'Github': Github,
  'Instagram': Instagram,
  'Linkedin': Linkedin,
  'Medium': Medium,
  'Reddit': Reddit,
  'Telegram': Telegram,
  'Tiktok': Tiktok,
  'Twitter': Twitter,
  'Web': Web,
  'Youtube': Youtube
}

export default icons;