import React from 'react';
import Coinmarketcap from '../pics/socials/coinmarketcap.svg';
import Twitter from '../pics/socials/twitter.svg';
import Github from '../pics/socials/github.svg';
import Coingecko from '../pics/socials/coingecko.svg';

class Modal extends React.Component {
  
  render() {

    let item = this.props.item;
  
    return (
      <div className="modal" style={{display: this.props.modalOpened ? 'block': 'none'}}>
        <div className="modal__inner">
          <div className="modal__bg"></div>
          <div className="container">
            <div className="modal__content">
              <div className="modal__close" onClick={() => this.props.closeModal()}>
                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z" fill="white"></path>
                  <path fillRule="evenodd" clipRule="evenodd" d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z" fill="white"></path>
                </svg>
              </div>
              <div className="m-item__header"> 
                <div className="img black"> { item && ( item.field_project.length > 0 ? <a href={ item.field_project.split('\u0022')[1] }><img src={ item ? this.props.mainURL + item.field_image : '' } alt="" /></a> : <img src={ item ? this.props.mainURL + item.field_image : '' } alt="" /> ) }</div>
                <div className="title"> 
                  <h3> <span className="mr-2">{ item && ( item.field_project.length > 0 ? <a href={ item.field_project.split('\u0022')[1] }>{ item && item.title }</a> : item && item.title ) }</span><span className={ item ? "tag-status " + item.field_stage.toLowerCase().replace("α","a").replace("β","b") : "tag-status " }>{ item ? item.field_stage : '' } </span></h3>
                  <div className="item-docs">
                    { item ? (item.field_docs ? (
                    <a className="external-link mr-3" href={ item ? item.field_docs : '' } target="_blank" rel="noreferrer nofollow">Docs
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.8333 15.8333H4.16667V4.16667H10V2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10H15.8333V15.8333ZM11.6667 2.5V4.16667H14.6583L6.46667 12.3583L7.64167 13.5333L15.8333 5.34167V8.33333H17.5V2.5H11.6667Z"> </path>
                      </svg></a>
                    ) : '') : '' }
                    { item ? (item.field_web ? (
                    <a className="external-link" href={ item ? item.field_web : '' } target="_blank" rel="noreferrer nofollow">Website
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.8333 15.8333H4.16667V4.16667H10V2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10H15.8333V15.8333ZM11.6667 2.5V4.16667H14.6583L6.46667 12.3583L7.64167 13.5333L15.8333 5.34167V8.33333H17.5V2.5H11.6667Z"> </path>
                      </svg></a>
                    ) : '') : '' }
                  </div>
                </div>
              </div>
              <div className="m-item__info">   
                <div className="row"> 
                  <div className="col-lg-8">
                    <div className="heading">About</div>
                    { item ? <p>{ this.props.convertHtmlEntities(item.body) }</p> : '' }
                    { item && ( item.field_project.length > 0 ? <p><a className="to-project-page btn btn-sm btn-link px-0" href={ item.field_project.split('\u0022')[1] }><span className="mr-2">To the project page </span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"></path>
                    </svg></a></p> : null ) }
                  </div>
                  <div className="col-lg-4">
                    <div className="info-right">
                      <div className="heading">Token name</div>
                      <div className="token-name">{ item ? (item.field_token_name ? item.field_token_name : 'N/A') : '' }</div>
                      <div className="tags"> { item ? ( (item.field_tags.length > 0) ? item.field_tags.split(' ').map(
                        (tag,id) => (
                          <span className="tag" key={id}>{tag}</span>
                        )) : '') : '' }</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="m-item__nets">{ item ? ( (item.field_networks.length > 0) ? item.field_networks.split('|').sort((a,b) => a.localeCompare(b)).map(
                  (net,id) => (
                    <div className="item__net" key={id}> <img src={ (this.props.icons[net]) ? this.props.icons[net] : this.props.icons['default'] } alt={net} title={net} /><span>{net}</span></div>
                  )) : '') : '' }
              </div>
              <div className="m-item__tags">{ item ? ( (item.field_search?.length > 0) ? item.field_search.split('|').map(
                  (search_tag,id) => (
                    <span className="item__tag" style={{cursor:"pointer"}} key={id} onClick={ (e) => { e.preventDefault(); this.props.searchItem(search_tag); this.props.closeModal(); } }>#{search_tag}</span>
                  )) : '') : '' }
              </div>
              <div className="row"> 
                <div className="col"> 
                  <div className="m-item__socials"> 
                    <ul className="socials">
                      { item ? (item.field_coinmarketcap ? (
                        <li><a href={ item.field_coinmarketcap } target="_blank" rel="noreferrer nofollow" title="CoinMarketCap"><img src={ Coinmarketcap } alt="" /></a></li>
                      ) : '' ) : '' }
                      { item ? (item.field_twitter ? (
                        <li><a href={ item.field_twitter } target="_blank" rel="noreferrer nofollow" title="Twitter"><img src={ Twitter } alt="" /></a></li>
                      ) : '' ) : '' }
                      { item ? (item.field_github ? (
                        <li><a href={ item.field_github } target="_blank" rel="noreferrer nofollow" title="Github"><img src={ Github } alt="" /></a></li>
                      ) : '' ) : '' }
                      { item ? (item.field_coingecko ? (
                        <li><a href={ item.field_coingecko } target="_blank" rel="noreferrer nofollow" title="Coingecko"><img src={ Coingecko } alt="" /></a></li>
                      ) : '' ) : '' }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;