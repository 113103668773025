import React from 'react';
import Slider from './slider.component';

import Bgimg from '../pics/bg/bg-lg-right-6.svg';
import Loader from '../pics/icons/i-loading.svg';
import config from '../config.json';

class Intro extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoaded: false
    };
  }

  async componentDidMount() {
    await this.getSlider();
  }

  async getSlider() {
    let slider;
    try {
      slider = await fetch(config.mainURL + config.sliderURL + config.formatURL)
        .then((res) => res.json())
        .then((json) => {
          if ('error' in json) {
            throw new Error( json["error"] );
          }
          else {
            return json;
          }
        })
        .catch(e => {
          console.log(e);
        });
    } catch(e) {
      console.log(e);
    }

    Promise.all([ slider ]).then( res => {
      this.setState({
        items: res[0],
        isLoaded: true
      });
    });
  }

  render() {
    return(
      <section className="sec-intro">
        <div className="bg-gradient"></div><img className="sec-bg bg-right d-none d-xl-block" src={ Bgimg } alt="" />
        <div className="container">
          <div className="row align-items-center"> 
            <div className="col-lg-6"> 
              <h1>NFT 2.0 AGGREGATOR<span className="text-grad d-block">FIRST IN WEB 3.0</span></h1>
              <p className="pr-lg-4">On this page, you can find various projects in the <a href="https://hackernoon.com/nft-20-the-era-of-programmable-assets" target="_blank" rel="noreferrer nofollow">NFT 2.0</a> sphere, also known as Utility NFTs, Smart NFTs, and programmable NFTs. To be more specific, NFT 2.0 includes financial, dynamic, wrapped (or charged), and many other types of non-fungible tokens. NFT 2.0 tokens themselves are part of programmable assets.</p>
            </div>
            <div className="col-lg-5 offset-lg-1">
              {
                !this.state.isLoaded ? (
                  <div className="ml-3">
                    <img src={Loader} alt="" /> 
                    <h4>Loading..</h4>
                  </div>
                ) : (
                  <Slider items={this.state.items} mainURL={config.mainURL} />
                )
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Intro;