import React from 'react';
import CardElement from './card.component';
import SearchCard from './searchcard.component';
import FilterNetwork from './filternets.component';
import FilterTech from './filtertech.component';
import Modal from './modal.component';
import icons from './icons.component';
import Loader from '../pics/icons/i-loading.svg';
import config from '../config.json';

class Cards extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        json: [],
        items: [],
        stat: [],
        tags: [],
        curnet: '',
        searchText: '',
        searchCards: '',
        param: '',
        defaultId: 250,
        loadnets: 7,
        curpage: 0,
        curtotal: 0,
        alltotal: 0,
        loaditems: 0,
        curitem: {},
        modalOpened: false,
        defaultCard: false,
        showLoadingMore: false,
        loadingMore: false,
        isLoaded: false
      };
      this.timeout = 0;
      this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event){
      if (event.keyCode === 27) {
        this.closeModal();
      }
    }

    async componentDidMount() {
      await this.getStat();
      this.getNetworkFromUrl();
      this.getSearchFromUrl();
      let url = this.getUrl(this.state.curnet,this.state.curpage);
      this.fetchItems(url,this.state.curnet);
      document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount(){
      document.removeEventListener("keydown", this.escFunction, false);
    }

    getUrl(network,page=0) {
      return config.mainURL + config.dataURL + config.formatURL + "&network=" + this.getNetwork(network) + "&page=" + page.toString();
    }

    async getStat() {

      // get total by card
      let alltotal;
      try {
        alltotal = await fetch(config.mainURL + config.totalURL + config.formatURL)
          .then((res) => res.json())
          .then((json) => {
        		if ('error' in json) {
        			throw new Error( json["error"] );
        		}
        		else {
              return json[0].total;
            }
          })
          .catch(e => {
        	  console.log(e);
          });
      } catch(e) {
        console.log(e);
      }

      // get overall total
      let stat;
      try {
        stat = await fetch(config.mainURL + config.statURL + config.formatURL)
          .then((res) => res.json())
          .then((json) => {
        		if ('error' in json) {
        			throw new Error( json["error"] );
        		}
        		else {
              return json;
            }
          })
          .catch(e => {
        	  console.log(e);
          });
      } catch(e) {
        console.log(e);
      }

      // get search tags
      let tags;
      try {
        tags = await fetch(config.mainURL + config.searchURL + config.formatURL)
          .then((res) => res.json())
          .then((json) => {
        		if ('error' in json) {
        			throw new Error( json["error"] );
        		}
        		else {
              return json;
            }
          })
          .catch(e => {
        	  console.log(e);
          });
      } catch(e) {
        console.log(e);
      }

      Promise.all([ alltotal, stat, tags ]).then( res => {
        this.setState({
          alltotal: res[0],
          stat: res[1],
          tags: res[2]
        });
      });
    }

    getTotal(network) {
      let curstat = this.state.alltotal;
      if (network) {
        try {
          const getstat = this.state.stat.filter(item => { return this.getNetwork(item.network) === network; });
          curstat = getstat[0].total;
        } catch(e) {}
      }
      return curstat;
    }
    
    getNetwork(network) {
      return (network) ? network.toLowerCase() : '';
    }
    
    getNetworkFromUrl() {
      try {
        let network = new URLSearchParams(this.props.location.search).get('network');
        if(network) {
          let curnet = this.getNetwork(network);
          this.setState({
            curnet: curnet
          });
          return curnet;
        }
        return;
      } catch(e) {
        // console.log(e);
        return;
      }
    }

    getSearchFromUrl() {
      try {
        let search = new URLSearchParams(this.props.location.search).get('search');
        if(search) {
          this.searchItem(search);
        }
      } catch(e) {
        // console.log(e);
      }
    }

    fetchItems(url,network='',page=0,search=0) {
      try {
        fetch(url)
          .then((res) => res.json())
          .then((json) => {
        		if ('error' in json) {
        			throw new Error( json["error"] );
        		}
        		else {
              if (this.state?.json.length && network === this.state?.curnet.toLowerCase() && page !== this.state?.curpage) {
                this.setState({
                  json: this.state.json.concat(json),
                  items: this.state.items.concat(json),
                  // items: this.state.items.concat( (!this.state.curnet) ? json : json.filter((item) => {
                  //   return item.field_networks.includes(this.state.curnet)
                  // }) ),
                });
              }
              else {
                this.setState({
                  json: json,
                  items: json,
                  curpage: 0
                });
              }
              if (page) {
                this.setState({
                  curpage: page
                });
              }
                            
              this.setState({
                curnet: network,
                curtotal: search ? json.length : this.getTotal(network),
                loaditems: json.length,
                showLoadingMore: json.length >= 8,
                isLoaded: true,
                loadingMore: false
	            });
        		}
          })
          .catch(e => {
        	  console.log(e);
          });
      } catch(e) {
        console.log(e);
      }
    }

    loadMore() {

      let page = this.state.curpage + 1;

      this.setState({
        loadingMore: true
      });

      if (this.state.searchText && this.state.searchCards) {
        let cards = this.state.searchCards.split(",");
        console.log("Load more " + page + " for " + this.state.searchText);
        let loadCards = cards.slice(parseInt(page) * 8);
        if (loadCards.length) {
          this.fetchItems(config.mainURL + config.searchURL + "/" + loadCards + config.formatURL,"",page,1);
        }
        else {
          this.setState({
            loaditems: 0,
            loadingMore: false,
            isLoaded: true
          });    
        }
      }
      else {
        let url = this.getUrl(this.state.curnet,page);
        this.fetchItems(url,this.state.curnet,page);
        console.log("Load more " + page);
      }
    }

    searchItem = (e) => {
      this.setState({
        searchText: e,
        isLoaded: false
      });
      if(this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {this.getItems(e)}, 500);
      const searchRow = document.getElementById('search-row');
      if(searchRow) {
        searchRow.scrollIntoView({ behavior: 'smooth' });
      }
    }

    getItems = (text) => {
      if(text !== '') {
        console.log("Search by tag:",text);
        const result = this.state.tags.filter(item => {
            return item.tags.toLowerCase().includes(text.toLowerCase())
        });
        if(result.length) {
          this.setState({
            json: [],
            items: []
          });    
          console.log(result);
          const cards = result.map(tag => tag.id).join(',');
          console.log(cards);
          this.fetchItems(config.mainURL + config.searchURL + "/" + cards + config.formatURL,"",0,1);
          this.setState({
            searchCards: cards,
            defaultCard: false
          });
        }
        else {
          this.fetchItems(config.mainURL + config.searchURL + "/" + this.state.defaultId + config.formatURL,"",0,1);
          this.setState({
            searchCards: '',
            defaultCard: true
          });
        }
      }
      else {
        // this.setState({
        //   items: this.state.json,
        //   curtotal: this.state.alltotal,
        //   loaditems: this.state.json.length
        // });
        const url = this.getUrl(this.state.curnet,this.state.curpage);
        this.fetchItems(url,this.state.curnet);
        this.setState({
          searchCards: '',
          defaultCard: false
        });
      }
    }

    filterByNet = (category) => {
      this.setState({
        searchText: '',
        searchCards: '',
        isLoaded: false,
        defaultCard: false
      });
      this.fetchItems(this.getUrl(category),this.getNetwork(category));
    }

    filterByTech = ([protocol, oracle, index]) => {
      const filtered = this.state.json.filter((item) => {
        if(protocol) {
          return item.field_tags.includes("Protocol");
        }
        if(oracle) {
          return item.field_tags.includes("Oracle");
        }
        if(index) {
          return item.field_tags.includes("Index");
        }
        return false;
      });
      this.setState({
        items: filtered,
        curtotal: (protocol && oracle && index) ? this.getTotal(this.state.curnet) : filtered.length
      });
    }
    
    moreFilters = (length=0) => {
      this.setState((prevState, props) => ({
        loadnets: (length) ? length : prevState.loadnets + 8
      }));
    }

    convertHtmlEntities(input) {
      const entities = {
        '&#039;': "'",
        '&quot;': '"',
        '&nbsp;': ' ',
      };
      return input.replace(/&#?\w+;/g, match => entities[match]);
    }
    
    openModal = (item) => {
      this.setState({
        curitem: item,
        modalOpened: true
      });
    }

    closeModal = () => {
      this.setState({
        curitem: {},
        modalOpened: false
      });
    }

    render() {
        const { isLoaded, showLoadingMore, items, loaditems, loadnets, curnet, curtotal, searchText, defaultCard } = this.state;

        return (
          <section className="sec-cards"> 
            <div className="container"> 
              <div className="sec-cards__filter">
                <FilterNetwork filterByNet={this.filterByNet} moreFilters={this.moreFilters} getNetworkFromUrl={this.getNetworkFromUrl} curnet={curnet} loadnets={loadnets} items={items} icons={icons} />
                <div className="row align-items-center"> 
                  <div className="col-lg-4">
                    <SearchCard searchItem={this.searchItem} searchText={searchText} />
                  </div>
                  <div className="col-12 col-sm">
                    <FilterTech filterByTech={this.filterByTech} />
                  </div>
                  <div className="col-12 col-sm-auto">
                    <div className="cards-total mb-4">
                      { curtotal ? (
                          <span>NFT projects: <strong className="text-grad">{curtotal}</strong></span>
                          ) : null }
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {
                  defaultCard ? (
                    <div className="col-lg-12"><h4 className="ml-1 mb-5">No project yet.. Learn about this one &dArr;</h4></div>
                  ) : null
                }
                {
                  !isLoaded ? (
                    <div className="ml-3">
                      <img src={Loader} alt="" /> 
                      <h4>Loading..</h4>
                    </div>
                  ) : (
                    <CardElement items={items} icons={icons} mainURL={config.mainURL} openModal={this.openModal} />
                  )
                }
              </div>
              <div className="row">
                <div className="col-12">
                {
                  isLoaded && showLoadingMore && loaditems === 8 && items.length ? (
                    <div className="btn btn-gray w-100" onClick={ () => { this.loadMore() } }>Load more { (this.state.loadingMore) ? (<img src={Loader} alt="" className="ml-3" /> ) : null }</div>                   
                  ) : ( ''
                  )
                }
                </div>
              </div>
            </div>
            { Object.keys(this.state.curitem).length === 0 ? null : (
              <Modal item={this.state.curitem} icons={icons} mainURL={config.mainURL} convertHtmlEntities={this.convertHtmlEntities} searchItem={this.searchItem} modalOpened={this.state.modalOpened} closeModal={this.closeModal} /> )}
          </section>
        );
    }
}    

export default Cards;