import React from 'react';

class CardElement extends React.Component {

  render() {
    let items = this.props.items;

    return (
      <>
      { (items.length) ? items.map((item,key) => (
          <div className="col-12 col-md-6 col-lg-4 col-xl-3 col-card" key={item.id}>
            <div className="sec-cards__item" onClick={ () => this.props.openModal(item) }> <span className={"tag-status " + item.field_stage.toLowerCase().replace("α","a").replace("β","b") }>{ item.field_stage }</span>
              <div className="img black"> <img src={ this.props.mainURL + item.field_image } alt="" /></div>
              <div className="info">
                <div className="name">{ item.title }</div>
                <div className="nets">{ (item.field_networks.length > 0) ? item.field_networks.split('|').sort((a,b) => a.localeCompare(b)).map(
                  (net,id) => id < 4 && (
                    <span key={id}> <img src={ (this.props.icons[net]) ? this.props.icons[net] : this.props.icons['default'] } alt={net} title={net} /></span>
                  )) : '' }{ (item.field_networks.length > 0) ? ((item.field_networks.split('|').length > 4) ? (<span>+{ item.field_networks.split('|').length - 4 } <small>more</small></span>) : '') : '' }</div>
                <div className="tags">{ (item.field_tags.length > 0) ? item.field_tags.split(' ').map(
                  (tag,id) => (
                    <span className="tag" key={id}>{tag}</span>
                  )) : '' }</div>
              </div>
            </div>
          </div>
      )) : null }
      </>
    );
  }
}

export default CardElement;