import { Link } from 'react-router-dom';

import Logo from '../pics/logo.svg';
import Youtube from '../pics/socials/youtube.svg';
import LinkedIn from '../pics/socials/linkedin.svg';
import Discord from '../pics/socials/discord.svg';
import Telegram from '../pics/socials/telegram.svg';
import TelegramDeweb from '../pics/socials/nft-news.svg';
import Twitter from '../pics/socials/twitter.svg';
import Medium from '../pics/socials/medium.svg';

const Header = () => (
  <div className="container">
    <Link className="s-header__logo scroll-link" to="/" rel="canonical"><img src={ Logo } alt="ENVELOP logo" /></Link>
    <div className="s-header__nav"> <Link to="/digests" rel="noopener noreferrer">Digests</Link><Link to="/books" rel="noopener noreferrer">Books</Link><a className="external-link" rel="noopener noreferrer" href="https://app.envelop.is/" target="_blank">App  
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.8333 15.8333H4.16667V4.16667H10V2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10H15.8333V15.8333ZM11.6667 2.5V4.16667H14.6583L6.46667 12.3583L7.64167 13.5333L15.8333 5.34167V8.33333H17.5V2.5H11.6667Z"> </path>
        </svg></a></div>
    <div className="s-header__socails">
      <div className="order-lg-3">
        <ul className="socials">
          <li><a href="https://t.me/envelop_en" rel="noreferrer nofollow" target="_blank" title="Telegram"><img src={ Telegram } alt="ENVELOP telegram group" /></a></li>
          <li><a href="https://www.youtube.com/c/ENVELOP" rel="noreferrer nofollow" target="_blank" title="YouTube"><img src={ Youtube } alt="ENVELOP. NFTs YouTube Channel" /></a></li>
          <li><a href="https://www.linkedin.com/company/niftsy" rel="noreferrer nofollow" target="_blank" title="LinkedIn"><img src={ LinkedIn } alt="NIFTSY is token" /></a></li>
          <li><a href="https://discord.gg/gtYcjqq76f" rel="noreferrer nofollow" target="_blank" title="Discord"><img src={ Discord } alt="ENVELOP Discord group" /></a></li>
          <li><a href="https://envelop.medium.com/" rel="noreferrer nofollow" target="_blank" title="Medium"><img src={ Medium } alt="Blog about Web 3.0" /></a></li>
          <li><a href="https://twitter.com/Envelop_project" rel="noreferrer nofollow" target="_blank" title="Twitter"><img src={ Twitter } alt="Our twitter" /></a></li>
          <li><a href="https://t.me/nonfungible_web" rel="noreferrer nofollow" target="_blank" title="NFT 2.0 News"><img src={ TelegramDeweb } alt="NFT 2.0 News" /></a></li>
        </ul>
      </div>
    </div>
  </div>
);

export default Header;