import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import smoothscroll from 'smoothscroll-polyfill';

import { useGAEventTracker } from '../helpers/UseGAEventTracker';
import Bgimg from '../pics/bg/bg-lg-right-6.svg';
import download_icon from '../pics/icons/btn-download.svg';
import book_cover from '../pics/books/The_Rising_of_NFT2_0_by_Envelop_cover.webp';
import inner_page from '../pics/books/inner-page.jpg';

smoothscroll.polyfill();

const BookPage = (props) => {
  const gaEventTracker = useGAEventTracker('Book');

  return (
    <>
      <Helmet>
        <title>Books on NFT 2.0 by Envelop</title>
        <meta name="description" content="Check out our books with NFT market insights." />
      </Helmet>
      <main className="s-main">
        <section className="sec-back"> 
          <div className="container">
          <Link to="/" className="btn btn-gray btn-sm"> 
            <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.6668 9.16659H6.52516L11.1835 4.50825L10.0002 3.33325L3.3335 9.99992L10.0002 16.6666L11.1752 15.4916L6.52516 10.8333H16.6668V9.16659Z" fill="white"></path>
            </svg><span>Back    </span></Link></div>
        </section>
        <section className="sec-intro">
            <div className="bg-gradient"></div><img className="sec-bg bg-right d-none d-xl-block" src={ Bgimg } alt="" />
            <div className="container">
              <div className="row align-items-center"> 
                <div className="col-lg-6"> 
                  <h1>NFT 2.0 Books<span className="text-grad d-block">By Envelop</span></h1>
                  <p className="pr-lg-4">On this page, you can find unique books and research papers in the <a href="https://hackernoon.com/nft-20-the-era-of-programmable-assets" target="_blank" rel="noreferrer nofollow">NFT 2.0</a> sphere, also known as Utility NFTs, Smart NFTs, and programmable NFTs. To be more specific, NFT 2.0 includes financial, dynamic, wrapped (or charged), and many other types of non-fungible tokens. NFT 2.0 tokens themselves are part of programmable assets.</p>
                </div>
                <div className="col-lg-6"> 
                  <div className="promo-book"> <a className="promo-book__link" href="/files/The_Rising_of_NFT2_0_by_Envelop.pdf" onClick={()=>gaEventTracker('bookpdf','Download PDF book')} target="_blank">
                      <div className="book"><img className="page page-left" src={ inner_page } alt="" /><img className="page page-right" src={ inner_page } alt="" /><img className="cover" src={ book_cover } alt="" /><img className="download" src={ download_icon } alt="" /></div>
                      <div className="caption">Check out our new book!</div></a></div>
                </div>
              </div>
            </div>
          </section>
      </main>
    </>
  );
}

export default BookPage;
