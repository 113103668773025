import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';
import Intro from './intro.component';
import Cards from './cards.component';
import Form from './form.component';
import Video from './video.component';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const MainPage = (props) => {
  const location = useLocation();
  return (
    <>
      <Helmet>
        <title>NFT 2.0 Aggregator by Envelop</title>
        <meta name="description" content="The service collects data on all projects in the NFT 2.0 sphere, also known as Utility NFTs, Smart NFTs, and programmable NFTs." />
      </Helmet>
      <main className="s-main">
        <Intro />
        <Cards location={location} {...props} />
        <div className="divider right"></div>
        <Video />
        <div className="divider left"></div>
        <Form />
      </main>
    </>
  );
}

export default MainPage;