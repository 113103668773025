import React from 'react';
import ReactGA from "react-ga4";
import { Routes, Route } from 'react-router-dom';
import './scss/styles.scss';
// import { useGAPageTracker } from './helpers/UseGAEventTracker';
import ScrollToTop from './helpers/ScrollToTop';
import ErrorPage from './components/error.component';
import Header from './components/header.component';
import Footer from './components/footer.component';
import Modal from './components/modal.component';
import MainPage from './components/mainpage.component';
import DigestPage from './components/digestpage.component';
import BookPage from './components/bookpage.component';
// import InnerPage from './components/innerpage.component';
import config from './config.json';

ReactGA.initialize(config.GAID);

export default function App() {

  // useGAPageTracker(window.location.pathname + window.location.search, "Main page view");
  
  return (
    <>
      <div className="wrapper">
        <header className="s-header">
          <Header />
        </header>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<MainPage />} />
          <Route exact path="/digests" element={<DigestPage />} />
          <Route exact path="/books" element={<BookPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <footer className="s-footer">
          <Footer />
        </footer>
      </div>
      <Modal />
    </>
  );
}
