import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useGAPageTracker, useGAEventTracker } from '../helpers/UseGAEventTracker';
import Bgimg from '../pics/bg/bg-lg-right-6.svg';
import download_icon from '../pics/icons/btn-download.svg';
import pdf_icon from '../pics/tag-pdf.svg';
import config from '../config.json';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const DigestPage = () => {

    const [years, setYears] = useState([]);
    const [activeYear, setActiveYear] = useState(0);
    const [notActive, setNotActive] = useState(0);
    const [maxId, setMaxId] = useState(0);

    useGAPageTracker(window.location.pathname + window.location.search, "Digests page view");
    const gaEventTracker = useGAEventTracker('Digests');
   
    useEffect(() => {
        const _years = [...new Set(Object.values(config.digests).map(i => i.year))];
        setYears(_years.reverse());
        setActiveYear(_years[0]);
        setMaxId(Object.keys(config.digests).length);
    }, []);


    const setExpand = (y) => {
        if(activeYear === y) {
            if(notActive) {
                setNotActive(0);
            }
            else {
                setNotActive(1);
            }
        }
        else {
            setNotActive(0);
            setActiveYear(y);
        }
    }

    const filteredByYear = Object.values(config.digests).filter(item => { return item.year === activeYear });

    const digestYear = (y,k) => {
        
        return(
            <div className="accordion-container" key={k} onClick={ () => setExpand(y) }>
                <div className={"panel " + ((activeYear === y && !notActive) ? "active" : "")}>
                    <div className="heading"> <span>{ y }</span>
                        <svg className="arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"></path>
                        </svg>
                    </div>
                    <div className="content">
                        <div className="row">
                            { Object.keys([...Array(Number(Object.keys(filteredByYear).length))]).reverse().map((i,k) => digestLink(i,k)) }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const digestLink = (n,k) => {
        
        const i = (filteredByYear[n]["id"] < 10) ? '0' + filteredByYear[n]["id"] : filteredByYear[n]["id"];
        
        return(
            <div className="col-md-6 col-xl-5 mr-xl-5" key={k}><a className="digest-item" href={`/files/NIFTSY_NFT_Digest_${i}.pdf`} onClick={()=>gaEventTracker(`digestpdf${i}`,'Download Digest PDF file')} target="_blank" rel="noreferrer">
                <div className="row"> 
                    <div className="col-4 col-lg-5"> 
                        <div className="digest-item__cover"><img className="cover" src={`/files/NIFTSY_NFT_Digest_${i}.png`} alt="" /><img className="pdf" src={ pdf_icon } alt="" /><img className="download" src={ download_icon } alt="" /></div>
                    </div>
                    <div className="col-8 col-lg-7">
                        <div className="digest-item__desc">
                            <div className="digest-title"><strong>Vol. {i} </strong><span className="mr-3">/ {filteredByYear[n]["month"]}</span>{(parseInt(filteredByYear[n]["id"]) === maxId - 1) ? <span className="digest-new">new</span> : null }</div>
                            <p style={{whiteSpace: "pre-wrap"}}>{filteredByYear[n]["text"]}</p>
                        </div>
                    </div>
                </div></a>
            </div>
        );
    }

    return(
        <>
            <Helmet>
                <title>Digests on NFT 2.0 by Envelop</title>
                <meta name="description" content="The most complete NFT market insights for fans, researchers, writers and degens in the monthly NFT Digest." />
            </Helmet>
            <main className="s-main"> 
                <section className="sec-back"> 
                <div className="container">
                <Link to="/" className="btn btn-gray btn-sm"> 
                    <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.6668 9.16659H6.52516L11.1835 4.50825L10.0002 3.33325L3.3335 9.99992L10.0002 16.6666L11.1752 15.4916L6.52516 10.8333H16.6668V9.16659Z" fill="white"></path>
                    </svg><span>Back    </span></Link></div>
                </section>
                <section className="sec-digest">
                    <div className="bg-gradient"></div><img className="sec-bg bg-right d-none d-xl-block" src={ Bgimg } alt="" />
                    <div className="container"> 
                        <div className="row">
                            <div className="col-lg-10">
                                <h1 className="mb-4">NFT 2.0 Digests </h1>
                                <p className="mb-2">The most complete NFT market insights for fans, researchers, writers and degens in the monthly NIFTSY NFT Digest.</p>
                                <p className="mt-0">Be the first to know about NFTs and NFT2.0 from different sectors and blockchains by subscribing to the blog <a href="https://envelop.medium.com" target="_blank" rel="noreferrer">envelop.medium.com</a></p>
                            </div>
                        </div>
                        <div className="digest-list">
                            { years.map((i,k) => digestYear(i,k)) }
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
};

export default DigestPage;